.activity-category-label {
  display: inline-block;
  padding: 5px 18px;
  border-radius: 20px;
  color: #fff; }
  .activity-category-label--se-defouler {
    background: -webkit-gradient(linear, left top, right top, from(#d3be46), to(#ff6b7b));
    background: linear-gradient(to right, #d3be46, #ff6b7b); }
  .activity-category-label--se-bouger {
    background: -webkit-gradient(linear, left top, right top, from(#bddb47), to(#77cacd));
    background: linear-gradient(to right, #bddb47, #77cacd); }
  .activity-category-label--se-relaxer {
    background: -webkit-gradient(linear, left top, right top, from(#b2e1e4), to(#77cacd));
    background: linear-gradient(to right, #b2e1e4, #77cacd); }

.company-title {
  color: #fff !important;
  position: absolute;
  top: 20px;
  font-size: 20px !important; }

.company-header {
  background-repeat: no-repeat;
  height: 300px !important;
  background-color: #101847;
  background-size: cover; }

.company-logo {
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center; }
  .company-logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.company-info {
  margin-top: 150px; }
  .company-info li {
    margin-top: 20px; }

.user-logo {
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center; }

.user-info {
  margin-top: 75px; }

.custom-color {
  background: #f4f3f8 !important; }

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #f4f3f8 !important; }

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fff !important; }

.dropzone {
  background: white;
  border-radius: 5px;
  border: 2px dashed #101847;
  -o-border-image: none;
     border-image: none;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.referent-picture {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.mail {
  width: 750px;
  height: 823px;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 70px 94px 242px 96px;
  background-image: url("/images/fond.png");
  font-family: 'Mukta', sans-serif; }
  .mail-logo {
    width: 190px;
    height: 60px; }
  .mail-portlet {
    height: 612px;
    padding: 40px; }
  .mail-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--dark-indigo);
    margin-top: 20px;
    margin-bottom: 20px; }
  .mail-text {
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.40;
    letter-spacing: normal;
    color: var(--black); }
  .mail-header {
    height: 94px !important;
    padding-top: 40px; }
  .mail-body {
    padding: 0px !important; }
  .mail-button span {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-family: 'Mukta', sans-serif; }
  .mail-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (max-width: 576px) {
  .centered-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; } }

.mt-20 {
  margin-top: 20px; }

.mt-10 {
  margin-top: 10px; }

.pull-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end; }

@media (max-width: 1025px) {
  .logout-btn {
    position: absolute;
    top: 15px;
    z-index: 10;
    right: 20px;
    color: #161E48;
    background-color: white; } }

@media (max-width: 576px) {
  .logout-btn {
    display: none; } }

@media (min-width: 576px) {
  .m-menu__nav.m-menu__nav--dropdown-submenu-arrow > li:last-child {
    display: none; } }

.overflow-wrap {
  overflow-wrap: break-word; }

.btn.m-btn--hover-accent:hover {
  background-color: #101847 !important;
  border-color: #101847 !important; }

.m-badge.m-badge--accent {
  background-color: #67e269 !important; }

.m--font-accent {
  color: #67e269 !important; }

.m-datatable > .m-datatable__pager
> .m-datatable__pager-nav
> li > .m-datatable__pager-link.m-datatable__pager-link--active {
  background: #101847 !important;
  color: white !important; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li
> .m-datatable__pager-link:hover {
  background: #101847 !important;
  color: white !important; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-info .m-datatable__pager-size .btn.dropdown-toggle:hover {
  background: #101847 !important; }

.form-control:focus {
  border-color: #101847 !important; }

.bootstrap-select.show > .dropdown-toggle.btn-light {
  border-color: #101847 !important; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #101847 !important; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-info .m-datatable__pager-size.show > .btn.dropdown-toggle {
  background: #101847; }

.btn.btn-outline-danger {
  color: #f18585 !important; }

.btn.btn-outline-danger:hover:not(:disabled) {
  border-color: #f18585;
  background: #f18585; }

.btn.m-btn--hover-accent:focus {
  border-color: #101847 !important;
  background-color: #101847 !important; }

.m-switch.m-switch--success:not(.m-switch--outline) input:empty ~ span::after {
  color: #67e269 !important; }

.m-switch.m-switch--success:not(.m-switch--outline) input:checked ~ span::before {
  background-color: #67e269; }

.m-switch.m-switch--success:not(.m-switch--outline) input:empty ~ span::before {
  background-color: #f18585; }

a {
  color: #101847 !important; }

.btn-primary {
  color: #fff !important;
  background-color: #101847;
  border-color: #101847; }
  .btn-primary:hover {
    background-color: #101847;
    border-color: #101847; }

.btn.m-btn--pill.btn-envisport.btn-sm.pull-right.mb-4 {
  color: white !important; }

.error-previous {
  font-size: 24px;
  color: white !important; }
  .error-previous a {
    color: white !important; }

.mail-body {
  height: 300px !important; }
